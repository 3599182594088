import {
  Button,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import Page from 'components/page'
import { Link } from 'react-router-dom'
import SponsorGuestButtons from 'routes/components/sponsorGuestButtons'
import SearchIcon from '@mui/icons-material/Search'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash'
import React, { useState } from 'react'
import { Box, styled } from '@mui/system'
import { fetchJsonOpts } from 'utils'

type Guest = {
  first: string
  last: string
  date_of_birth: string
  pid: string
  value: string
}

const StyledSpan = styled('span')({
  color: 'red',
})

function FrontPage() {
  const [t] = useTranslation('common')
  const [guests, setGuests] = useState<Guest[]>([])
  const [searchHasInput, setSearchHasInput] = useState(false)

  const getGuests = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setSearchHasInput(true)
      fetch(
        `/api/ui/v1/person/search/?q=${event.target.value}`,
        fetchJsonOpts()
      )
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
          setSearchHasInput(false)
          return []
        })
        .then((responseJson) => {
          setGuests(responseJson)
        })
        .catch(() => {})
    } else {
      setSearchHasInput(false)
      setGuests([])
    }
  }
  return (
    <Page>
      <SponsorGuestButtons registerNewGuestActive />
      <Typography variant="h2" sx={{ marginBottom: '1rem' }}>
        {t('register.registerHeading')}
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
        {t('register.registerText')}
      </Typography>
      <Box
        sx={{
          borderStyle: () => (guests.length > 0 ? 'solid' : 'none'),
          borderRadius: '0.3125rem',
          borderColor: 'secondary.main',
          padding: '0.4375 0.75rem',
          marginBottom: '2rem',
        }}
      >
        <TextField
          variant="standard"
          error={searchHasInput && guests.length === 0}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          fullWidth
          placeholder={t('register.placeHolder')}
          onChange={debounce(getGuests, 600)}
        />
        {guests.length === 0 && searchHasInput ? (
          <StyledSpan>{t('register.noResults')}</StyledSpan>
        ) : (
          guests.map((guest) => {
            const guestTo = `/sponsor/guest/${guest.pid}`
            return (
              <MenuItem
                sx={{
                  '&:hover': {
                    fontWeight: 'bold',
                  },
                }}
                key={`${guest.pid}-${guest.value}`}
                component={Link}
                to={guestTo}
              >
                {guest.first} {guest.last} {guest.date_of_birth ? `(${guest.date_of_birth})` : ''}
                <br />
                {guest.value}
              </MenuItem>
            )
          })
        )}
      </Box>
      <Button
        variant="contained"
        color="secondary"
        component={Link}
        to="/register/new"
      >
        {t('register.registerButtonText')}
      </Button>
    </Page>
  )
}
export default FrontPage
